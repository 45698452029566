import request from '@/utils/request'
// 模板列表
export function getMsgList(data) {
  return request('post', '/apm/message/template/list', data)
}

// 小程序列表
export function getAppTypeList() {
  return request('get', '/apm/message/template/get/appType')
}

// 模板类型列表
export function getTemplateTypeList(templateId) {
  return request('get', '/apm/message/template/get/typeList?templateId=' + templateId)
}

// 新增模板
export function getTableAdd(data) {
  return request('post', '/apm/message/template/create', data)
}

// 编辑模板
export function getTableEdit(data) {
  return request('post', '/apm/message/template/update', data)
}

// 删除
export function getTableDel(data) {
  return request('post', '/apm/message/template/del', data)
}