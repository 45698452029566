<template>
  <div class="page">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增</el-button>
      </div>
    </section>

    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="模板名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.templateName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="模板ID" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.wechatTemplateId || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="模板类型" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.templateTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="跳转小程序" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.appletTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="跳转路径" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.pagePath || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="模板名称" prop="templateName">
            <el-input v-model.trim="params.templateName" autocomplete="off" maxlength="16" placeholder="请填写模板名称"></el-input>
          </el-form-item>
          <el-form-item label="模板ID" prop="wechatTemplateId">
            <el-input v-model.trim="params.wechatTemplateId" autocomplete="off" placeholder="请填写模板ID"></el-input>
          </el-form-item>
          <el-form-item label="模板类型" prop="templateType">
            <el-select v-model="params.templateType" placeholder="请选择模板类型">
              <el-option v-for="item in templateTypeList" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跳转路径" prop="pagePath">
            <el-input v-model.trim="params.pagePath" autocomplete="off" maxlength="50" placeholder="请填写跳转路径"></el-input>
          </el-form-item>
          <el-form-item label="跳转小程序" prop="appletType">
            <el-select v-model="params.appletType" placeholder="请选择跳转小程序">
              <el-option v-for="item in appTypeList" :key="item.clientType" :label="item.clientTypeName"
                :value="item.clientType"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板内容" prop="templateContent">
            <el-input type="textarea" :rows="5" v-model.trim="params.templateContent" autocomplete="off" maxlength="300"
              placeholder="请填写模板内容"></el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMsgList,
  getAppTypeList,
  getTemplateTypeList,
  getTableAdd,
  getTableEdit,
  getTableDel
} from "@/api/setting/msg"
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      appTypeList: [],//小程序列表
      templateTypeList: [],// 模板类型列表
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
      },
      // 弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // from表单
      rules,
      params: {
        templateName: "",
        wechatTemplateId: "",
        templateType: "",
        pagePath: "",
        appletType: "",
        templateContent: ""
      },
    }
  },
  watch: {
    params: {
      deep: true,
      handler(val) {
        if (val.templateId < 0) {
          this.params.templateId = 0
        }
      },
    },
  },
  mounted() {
    this.getMsgList();// 【请求】表格数据
    this.getAppTypeList();// 【请求】表格数据
  },
  methods: {
    // 【请求】模板列表
    getMsgList() {
      let data = this.tableParams;
      getMsgList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】小程序列表
    getAppTypeList() {
      let data = this.tableParams;
      getAppTypeList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.appTypeList = res.data;
        }
      });
    },

    // 【请求】模板类型
    getTemplateTypeList(templateId) {
      getTemplateTypeList(templateId).then((res) => {
        if (res.isSuccess == "yes") {
          this.templateTypeList = res.data;
        }
      });
    },

    // 【请求】添加
    getTableAdd() {
      let data = this.params;
      getTableAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.reload();// 【请求】表格数据
        }
      });
    },

    // 【请求】编辑
    getTableEdit() {
      let data = this.params;
      getTableEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.getMsgList();// 【请求】表格数据
        }
      });
    },


    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getMsgList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getMsgList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】编辑
    onAdd() {
      this.getTemplateTypeList("")
      this.editShow = true;
      this.editTitle = "新增";
      this.params = {
        templateName: "",
        templateType: "",
        pagePath: "",
        appletType: "",
        templateContent: ""
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】详情
    onDel(row) {
      console.log('row: ', row);
    },

    // 【监听】编辑
    onEdit(row) {
      console.log('row: ', row);
      this.getTemplateTypeList(row.templateId)
      this.editShow = true;
      this.editTitle = "编辑";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.templateId) {
            this.getTableEdit();
          } else {
            this.getTableAdd();
          }
        } else {
          return false;
        }
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = row;
      this.$confirm('你确定要删除该吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getTableDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getMsgList();
          }
        })
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>